    import { Canvas, useFrame, useLoader, useThree } from '@react-three/fiber'
    import React, { Suspense, useState, useEffect } from 'react'
    import { SpotLight, Text, ScrollControls, Scroll, Html } from '@react-three/drei'
    import { EffectComposer, Vignette } from '@react-three/postprocessing'
    import { TextureLoader, Vector3 } from 'three'

    const WallArt = ({ art, i }) => {
      const { width: w, height: h } = useThree((state) => state.viewport);
      const gap = 2;
      const imageWidth = 5;
      const texture = useLoader(TextureLoader, art.imgPath)

      return (
          <group>
            <SpotLight
                position={[(i + 1) * (imageWidth + gap) + (i + 1) - w / 4, 2.5, 1]}
                penumbra={1}
                angle={0.6}
                attenuation={1}
                anglePower={5}
                intensity={10}
                distance={10}
                castShadow
                color={0xffffff}
            />
            <mesh castShadow position={[(i + 1) * (imageWidth + gap) + (i + 1), 0, 0]}>
              <boxBufferGeometry attach="geometry" args={[imageWidth, h / 2, 0.07]} />
              <meshStandardMaterial
                  attach="material"
                  map={texture}
                  roughness={0.2}
                  metalness={0.8}
              />
            </mesh>

            <mesh position={[(i + 1) * (imageWidth + gap) + (i + 1), -2.5, 0]}>
              <planeGeometry args={[2, 0.5]} />
              <meshStandardMaterial color={0xFAEBD7} />
              <Text
                  position-z={0}
                  scale={[2, 2, 2]}
                  color="black"
                  anchorX="center"
                  anchorY="middle"
                  font="https://fonts.gstatic.com/s/sacramento/v5/buEzpo6gcdjy0EiZMBUG4C0f-w.woff"
              >
                {art.title}
              </Text>
            </mesh>
          </group>
      )
    }

    const Scene = ({ artPieces,description,user,title }) => {
      const { width: screenWidth } = useThree((state) => state.viewport);
      const textScale = screenWidth < 5.5 ? 2 : 4;

      return (
          <Suspense fallback={
            <Html style={{ fontSize: '6vw', whiteSpace: 'nowrap', color: 'white' }} center>
              Loading 3D Art Gallery...
            </Html>
          }>
            <ScrollControls  horizontal damping={4} pages={25* Math.exp(-0.11 * screenWidth)} distance={1}>
              <Scroll>
                <Text
                    position-z={0}
                    anchorX="center"
                    anchorY="bottom"
                    scale={[textScale, textScale, textScale]}
                    color="#94A6FF"
                    font="https://fonts.gstatic.com/s/sacramento/v5/buEzpo6gcdjy0EiZMBUG4C0f-w.woff"
                    castShadow
                >
                    {title}
                </Text>
                <Text
                    position-z={1}
                    anchorX="center"
                    anchorY="top"
                    scale={[textScale, textScale, textScale]}
                    color="#FBA90A"
                    font="https://fonts.gstatic.com/s/sacramento/v5/buEzpo6gcdjy0EiZMBUG4C0f-w.woff"
                    castShadow
                >
                    {description}
                </Text>
                <Text
                    position={[0, -0.5, 1.5]}
                    anchorX="center"
                    anchorY="top"
                    font="https://fonts.gstatic.com/s/sacramento/v5/buEzpo6gcdjy0EiZMBUG4C0f-w.woff"
                >
                  ~ {user}
                </Text>

                {artPieces.map((art, i) => (
                    <WallArt key={i} i={i} art={art} />
                ))}
              </Scroll>
            </ScrollControls>
          </Suspense >
      )
    }

    const Rig = () => {
      const { camera, mouse } = useThree()
      const vec = new Vector3()
      return useFrame(() => camera.position.lerp(vec.set(mouse.x * 0.5, mouse.y * 0.5, camera.position.z), 0.2))
    }
    const Background = ({ backgroundImage }) => {
        const texture = useLoader(TextureLoader, backgroundImage);

        return (
            <mesh position={[0, 0, -10]}>
                <planeGeometry args={[50, 50]} />
                <meshBasicMaterial map={texture} />
            </mesh>
        );
    };
    function App() {
      const [artPieces, setArtPieces] = useState([])
      const [backgroundColor, setBackgroundColor] = useState()
      const [galleryInfo, setGalleryInfo] = useState({})

      useEffect(() => {
        window.addEventListener("message", (event) => {
            if (event.data.type === "SET_GALLERY_INFO") {

                console.log('event.data', event.data)
                setGalleryInfo(event.data);
            }
          if (event.data.type === "SET_ART_PIECES") {

              console.log('event.data', event.data)
            setArtPieces(event.data.artPieces);
          }
            if (event.data.type === "SET_BG") {
                console.log(event.data)
                setBackgroundColor(event.data.backgroundColor);
            }
        });
        return () =>{
            window.removeEventListener("message", (event) => {
                if (event.data.type === "SET_ART_PIECES") {
                    setArtPieces(event.data.artPieces);
                }
                if (event.data.type === "SET_BG") {
                    setBackgroundColor(event.data.backgroundImage);
                }
            });
        }
      }, []);

      return (
          <Canvas shadows camera style={{width:"100vw !important"}}>
            <ambientLight intensity={0.6} color={0xffffff} />

            <mesh position={[0, 0, -0.1]} receiveShadow  >
                        <planeGeometry args={[20, 15]}/>
                        <meshStandardMaterial color={backgroundColor}/>
            </mesh>
              <Scene artPieces={artPieces} description={galleryInfo.galleryDescription} user={galleryInfo.user} title={galleryInfo.galleryTitle} />

              <EffectComposer>
              <Vignette eskil={false} offset={0.1} darkness={0.5} />
            </EffectComposer>
            <Rig />
          </Canvas>
      )
    }

    export default App;
